@import "../../scss/variables.scss";

#sidebar {
    background-color: $secondary;
    color: $white;
    min-width: ($sidebar-margin * 4);
    width: ($sidebar-margin * 4);
    margin-top: -2.5rem;
    padding: 0;
    z-index: $zindex-fixed;

    &.sidebar-expanded {
        min-width: 14rem;
        width: 14rem;

        #sidebar-collapse {
            right: $sidebar-margin * 2;
        }
    }

    nav {
        width: $sidebar-width;
        min-width: $sidebar-width;
    }

    .parentItem {
        &:hover, &[aria-expanded=true] {
            color: $white;
            background-color: darken($secondary, 5%);
        }
    }

    .subItem {
        background-color: lighten($secondary, 10%);
    
        &:hover, &[aria-expanded=true] {
            background-color: lighten($secondary, 5%);
        }
    }

    button {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: $white;
        padding: .75rem;
        width: 100%;
        border-width: 0;
        box-shadow: none;
        border-radius: 0;
    }

    a {
        color: $black;
        background-color: $gray-200;
        padding: .75rem .75rem .75rem 1rem;

        &:hover, &.active {
            color: $black;
            background-color: $gray-300;
        }
    }
}

#nav-wrapper {
    position: absolute;
    top: 60px;
    right: 0;
    bottom: 0;
    left: 0;
    overflow-y: auto;
}

#sidebar-collapse {
    position: absolute;
    bottom: 0;
    right: $sidebar-margin;
    cursor: pointer;
}